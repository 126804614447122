import { useGetTotalFiltersQuery as useGetTotalFiltersQueryTokko } from '@apis/originalTokkoApi/properties'
import { useGetTotalFilterQuery as useGetTotalFiltersQueryMiddleware } from '@apis/mediacore/owner'
import { useGetSummaryQuery as useGetTotalFiltersQueryMediacore } from '@apis/mediacore/properties'
import { useGetNewPropertiesQuery as useGetPropertiesMiddlewareTokkoQuery } from '@apis/mediacore/owner'

interface Props {
  OPERATION?: string
  TYPE?: any
  API_KEY?: string
  CUSTOM_TAGS?: number[]
}

function useQuerySummary(props?: Props) {
  const middlewarev2: boolean = process.env.GATSBY_USE_MIDDLEWARE_V2?.toLowerCase() === 'true'

  const {
    data: allSummaryData,
    isLoading,
    isError,
    isFetching,
  } = props?.API_KEY
    ? !middlewarev2
      ? useGetTotalFiltersQueryTokko({
          //Old Middleware
          API_KEY: props.API_KEY,
          OPERATION: props?.OPERATION,
          TYPE: props?.TYPE,
          CTAG: props?.CUSTOM_TAGS,
        })
      : useGetTotalFiltersQueryMiddleware({
          //New Middleware
          API_KEY: props.API_KEY,
          OPERATION: props?.OPERATION,
          TYPE: props?.TYPE,
          CTAG: props?.CUSTOM_TAGS,
        })
    : useGetTotalFiltersQueryMediacore({
        CLIENT_ID: process.env.GATSBY_CLIENTID,
        OPERATION: props?.OPERATION,
        TYPE: props?.TYPE,
        // CTAG: 11329,
      })

  return {
    allSummaryData,
    isLoading,
    isError,
    isFetching,
    isMediacore: props?.API_KEY ? false : true,
  }
}

export default useQuerySummary
