import React from 'react'
import { RealEstateDataFooterData, RealEstateDataPage } from 'types'
import Footer from '@components/footer/1'
interface ExternalProps {
  pages: RealEstateDataPage[]
  currentPage: RealEstateDataPage[]
  development_id?: string
  property_id?: string
}

type CombinedProps = RealEstateDataFooterData & ExternalProps

const Footer_6 = ({
  titles,
  main_office,
  pages,
  modern_version,
  image_gatsby,
  global_data,
  development_id,
  property_id,
  buttonCompare,
  buttonHeart,
  advanced_whatsapp,
  hidden,
  file,
  footer_files,
  backgroundColor,
  backgroundHexa,
  image_bottom_gatsby,
}: CombinedProps) => {
  return (
    <Footer
      titles={titles}
      main_office={main_office}
      pages={pages}
      modern_version={modern_version}
      image_gatsby={image_gatsby}
      global_data={global_data}
      development_id={development_id}
      property_id={property_id}
      advanced_whatsapp={advanced_whatsapp}
      buttonCompare={buttonCompare}
      buttonHeart={buttonHeart}
      hidden={hidden}
      file={file}
      footer_files={footer_files}
      topLogo={true}
      backgroundColor={backgroundColor}
      backgroundHexa={backgroundHexa}
      image_bottom_gatsby={image_bottom_gatsby}
    />
  )
}

export default Footer_6
