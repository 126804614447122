import {
  Development,
  JobsRankingResponse,
  ObjectTokkoFilters,
  ObjectTokkoLocations,
  Property,
  ResponseRankingDevQuery,
  ResponseRankingQuery,
} from 'types'
import { mediacoreApi } from '../mediacoreApi'
import {
  getChildrens,
  getFathers,
  getGrandfathers,
  getOnlyArrayOperationById,
  getSumaryFilterDefault,
  makeParamsCoordinates,
} from '@helpers/helper.filters'
import { removeDuplicateProperties } from '@helpers/helper.properties'

interface Params {
  API_KEY?: string
  OPERATION?: string
  TYPE: any
  CTAG?: number[]
}

const ownerExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewProperties: builder.query({
      query: ({ params_get, params_post, coordinates, polygonCoordinates, CTAG }) => ({
        url: `owner/tokko-propiedades/search/${params_get}&client=${process.env.GATSBY_CLIENTID}${
          CTAG ? '&custom_tags=' + CTAG : ''
        }`,
        // body: makeParamsCoordinates(coordinates, polygonCoordinates),
        body: { ...makeParamsCoordinates(coordinates, polygonCoordinates), ...params_post },
        // body: params_get,
        method: 'POST',
      }),
      transformResponse: ({ meta, objects }) => {
        return { meta, objects: removeDuplicateProperties(objects) }
      },
    }),
    getRanking: builder.query<ResponseRankingQuery[], void>({
      query: () => `owner/externo/tokko-propiedades/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevRanking: builder.query<ResponseRankingDevQuery[], void>({
      query: () => `owner/externo/tokko-desarrollos/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getMediacoreDevRanking: builder.query<ResponseRankingDevQuery[], void>({
      query: () => `site/desarrollos/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getJobsRanking: builder.query<JobsRankingResponse[], void>({
      query: () => `jobs/posiciones/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevelopmentsMiddleware: builder.query<Development[], void>({
      query: () => `owner/externo/tokko-desarrollos/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevelopmentMiddleware: builder.query<Development, { DEVELOPMENT_ID?: string }>({
      query: ({ DEVELOPMENT_ID }) =>
        `owner/externo/tokko-desarrollos/${DEVELOPMENT_ID}?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getPropertyMiddleware: builder.query<Property, { PROPERTY_ID?: string }>({
      query: ({ PROPERTY_ID }) =>
        `owner/tokko-propiedades/${PROPERTY_ID}/ficha?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getNewUnities: builder.query({
      query: ({ DEVELOPMENT_ID }) => ({
        url: `owner/tokko-propiedades/search/?limit=1000&client=${process.env.GATSBY_CLIENTID}`,
        body: { development_id: DEVELOPMENT_ID },
        method: 'POST',
      }),
      transformResponse: ({ objects }) => {
        return objects
      },
    }),
    getTotalFilter: builder.query<ObjectTokkoFilters, Params>({
      query: ({ OPERATION = '', TYPE = [], CTAG = [] }) => ({
        url: `owner/externo/tokko-propiedades/get_search_summary/?client=${
          process.env.GATSBY_CLIENTID
        }${OPERATION ? '&operation_types=' + getOnlyArrayOperationById(OPERATION) : ''}${
          CTAG ? '&custom_tags=' + CTAG : ''
        }`,
        method: 'GET',
      }),
    }),
    getLocations: builder.query<ObjectTokkoLocations, Params>({
      query: ({ OPERATION = '', TYPE = [], CTAG = [] }) => ({
        url: `owner/tokko-locations/public/?client=${process.env.GATSBY_CLIENTID}${
          OPERATION ? '&operation_types=' + getOnlyArrayOperationById(OPERATION) : ''
        }${
          TYPE
            ? '&property_types=' + TYPE?.map((element: { name: string; id: string }) => element.id)
            : ''
        }${CTAG ? '&custom_tags=' + CTAG : ''}`,
        method: 'GET',
      }),

      transformResponse: ({ meta, objects, locations }) => {
        const gfs = getGrandfathers({ objects, locations })
        const result: any = []
        for (const gf of gfs) {
          const data = gf[1]
          const extractData = (obj: any) => {
            Object.entries(obj).forEach(([key, value]: any) => {
              if (key !== 'count' && typeof value === 'object' && value !== null) {
                if (value.id) {
                  result.push({ name: key.replace(/_/g, ' '), id: value.id })
                }
                // Llamada recursiva para explorar más niveles
                extractData(value)
              }
            })
          }
          extractData(data)
          // Object.entries(data).forEach(([parentName, value]: any) => {
          //   // Agregar el barrio principal
          //   if (value.id) {
          //     result.push({ name: parentName, id: value.id })
          //   }

          //   // Agregar sub-barrios si existen
          //   Object.entries(value).forEach(([subName, subValue]: any) => {
          //     if (subName !== 'count' && subValue.id) {
          //       result.push({ name: subName.replace(/_/g, ' '), id: subValue.id })
          //     }
          //   })
          // })
        }
        console.log(result)
        return { objects: result, meta, locations }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNewPropertiesQuery,
  useGetRankingQuery,
  useGetDevRankingQuery,
  useGetDevelopmentsMiddlewareQuery,
  useGetDevelopmentMiddlewareQuery,
  useGetPropertyMiddlewareQuery,
  useGetNewUnitiesQuery,
  useGetTotalFilterQuery,
  useGetMediacoreDevRankingQuery,
  useGetJobsRankingQuery,
  useGetLocationsQuery,
} = ownerExtended
